import {noop} from 'lodash'
import React, {Dispatch, SetStateAction, useContext, useState} from 'react'

interface ShellContext {
  boxed: boolean
  setBoxed: Dispatch<SetStateAction<boolean>>
  compact: boolean
  setCompact: Dispatch<SetStateAction<boolean>>
  scrolled: boolean
}

const ShellContext = React.createContext<ShellContext>({
  boxed: false,
  setBoxed: noop,
  compact: false,
  setCompact: noop,
  scrolled: false
})

interface ShellProviderProps {
  defaultBoxed: boolean
  defaultCompact: boolean
  scrolled: boolean
}

export const ShellProvider: React.FC<ShellProviderProps> = ({
  defaultBoxed,
  defaultCompact,
  scrolled = false,
  children
}) => {
  const [boxed, setBoxed] = useState(defaultBoxed)
  const [compact, setCompact] = useState(defaultCompact)
  return (
    <ShellContext.Provider
      value={{
        boxed,
        setBoxed,
        compact,
        setCompact,
        scrolled
      }}
    >
      {children}
    </ShellContext.Provider>
  )
}

export const useShellContext = () => useContext(ShellContext)
