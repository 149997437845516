/**
 * Each stage refers to a .env.<stage> and defines differences between qa, uat and prod builds.
 */
export const STAGES = ['qa', 'uat', 'uatr', 'regression', 'prod']
const STAGES_HPRODUCE = ['qa', 'uat', 'regression', 'prod']

/**
 * Which products are included in Monorepo?
 * The product names are the same as the filenames in ./packages
 */
export const PRODUCTS = [
  'adminconsole',
  'authenticator',
  'hcem',
  'hproduce',
  'hub',
  'insight',
  'onsite',
  'truckmonitor',
  'equippulse',
  'metricsui',
  'predict',
  'pom',
  'legal',
  'datalink',
  'slot'
]

/**
 * Which packages should be covered by the coverage report
 * Must be a folder in ./packages
 */
export const COVERAGE_PACKAGES = [...PRODUCTS, 'uikit', 'apiclient', 'automate']

/**
 * Which packages should be covered by the eslint report
 * Must be a folder in ./packages
 */
export const ESLINT_PACKAGES = [...PRODUCTS, 'uikit', 'apiclient', 'automate', 'metricsapi']

/**
 * Shorter name for the product.
 */
export const PRODUCT_TO_SHORT_NAME = {
  // use by default the full name
  ...PRODUCTS.reduce((acc, curr) => {
    acc[curr] = curr
    return acc
  }, {}),
  // override with shorter version if necessary
  adminconsole: 'admin',
  authenticator: 'auth',
  equippulse: 'equippulse',
  hcem: 'hcem',
  hproduce: 'hproduce',
  hub: 'hub',
  onsite: 'onsite_preview', // FIXME: reduce length, need to rename env vars on Gitlab
  insight: 'insight',
  truckmonitor: 'tmon',
  predict: 'predict',
  slot: 'slot'
}

/**
 * bucket name on Azure storage account
 */
export const PRODUCT_TO_AZURE_STORAGE_ACCOUNT_BUCKET_NAME = {
  adminconsole: 'admin',
  authenticator: 'auth',
  equippulse: 'maintain',
  hcem: 'hcem',
  hproduce: 'hproduce',
  hub: 'hub',
  onsite: 'onsite',
  insight: 'insight',
  truckmonitor: 'tmon',
  metricsui: 'metricsui',
  predict: 'predict',
  legal: 'legal',
  datalink: 'dlportal',
  pom: 'pom',
  slot: 'slot'
}

/**
 * bucket name on CDN
 */
export const PRODUCT_TO_CDN_NAME = {
  adminconsole: 'manage',
  authenticator: 'auth',
  equippulse: 'maintain',
  hcem: 'hcem',
  hproduce: 'hproduce',
  hub: 'hub',
  onsite: 'onsite',
  insight: 'insight',
  truckmonitor: 'tmon',
  metricsui: 'metricsui',
  predict: 'predict',
  legal: 'legaldocs',
  datalink: 'dlportal',
  pom: 'pom',
  slot: 'slot'
}

/**
 * Which products have which stages?
 */
export const PRODUCT_TO_STAGES = {
  adminconsole: [...STAGES],
  authenticator: [...STAGES],
  equippulse: [...STAGES_HPRODUCE],
  hcem: [...STAGES_HPRODUCE],
  hproduce: [...STAGES_HPRODUCE],
  hub: [...STAGES],
  insight: [...STAGES_HPRODUCE],
  onsite: [...STAGES],
  truckmonitor: [...STAGES],
  predict: [...STAGES_HPRODUCE],
  legal: ['qa', 'uat', 'prod'],
  datalink: [...STAGES],
  metricsui: ['qa'],
  pom: [...STAGES_HPRODUCE],
  slot: [...STAGES]
}
