// product is the folder name in MONOREPO, e.g., ./package/product
import path from 'path'

export const azureStorageBuckets = {
  // HUB
  'hub-qa': 'hconnecthubqa', // https://hconnecthubqa.z6.web.core.windows.net/
  'hub-uat': 'hconnecthubuat', // https://hconnecthubuat.z6.web.core.windows.net/
  'hub-uatr': 'hconnecthubuatr', // https://hconnecthubuatr.z6.web.core.windows.net/
  'hub-regression': 'hconnecthubregression', // https://hconnecthubregression.z6.web.core.windows.net/
  'hub-prod': 'hconnecthubprod', // https://hconnecthubprod.z6.web.core.windows.net/

  // HCEM
  'hcem-qa': 'hconnecthcemqa', // https://hconnecthcemqa.z6.web.core.windows.net/
  'hcem-uat': 'hconnecthcemuat', // https://hconnecthcemuat.z6.web.core.windows.net/
  'hcem-regression': 'hconnecthcemregression', // https://hconnecthcemregression.z6.web.core.windows.net/
  'hcem-prod': 'hconnecthcemprod', // https://hconnecthcemprod.z6.web.core.windows.net/

  // Authenticator
  'authenticator-qa': 'hconnectauthqa', // https://hconnectauthqa.z6.web.core.windows.net/
  'authenticator-uat': 'hconnectauthuat', // https://hconnectauthuat.z6.web.core.windows.net/
  'authenticator-uatr': 'hconnectauthuatr', // https://hconnectauthuatr.z6.web.core.windows.net/
  'authenticator-regression': 'hconnectauthregression', // https://hconnectauthregression.z6.web.core.windows.net/
  'authenticator-prod': 'hconnectauthprod', // https://hconnectauthprod.z6.web.core.windows.net/

  // Legal
  'legal-qa': 'hconnectlegaldocsqa', // https://hconnectlegaldocsqa.z6.web.core.windows.net/
  'legal-uat': 'hconnectlegaldocsuat', // https://hconnectlegaldocsuat.z6.web.core.windows.net/
  'legal-prod': 'hconnectlegaldocsprod', // https://hconnectlegaldocsprod.z6.web.core.windows.net/

  // DataLink
  'datalink-qa': 'hconnectdlportalqa', // https://hconnectdlportalqa.z6.web.core.windows.net/
  'datalink-uat': 'hconnectdlportaluat', // https://hconnectdlportaluat.z6.web.core.windows.net/
  'datalink-uatr': 'hconnectdlportaluatr', // https://hconnectdlportaluatr.z6.web.core.windows.net/
  'datalink-regression': 'hconnectdlportalreg', // https://hconnectdlportalreg.z6.web.core.windows.net/
  'datalink-prod': 'hconnectdlportalprod', // https://hconnectdlportalprod.z6.web.core.windows.net/

  // Adminconsole
  'adminconsole-qa': 'hconnectadminqa', // https://hconnectadminqa.z6.web.core.windows.net/
  'adminconsole-uat': 'hconnectadminuat', // https://hconnectadminuat.z6.web.core.windows.net/
  'adminconsole-uatr': 'hconnectadminuatr', // https://hconnectadminuatr.z6.web.core.windows.net/
  'adminconsole-regression': 'hconnectadminregression', // https://hconnectadminregression.z6.web.core.windows.net/
  'adminconsole-prod': 'hconnectadminprod', // https://hconnectadminprod.z6.web.core.windows.net/

  // Onsite
  'onsite-qa': 'hconnectonsiteqa', // https://hconnectonsiteqa.z6.web.core.windows.net/
  'onsite-uat': 'hconnectonsiteuat', // https://hconnectonsiteuat.z6.web.core.windows.net/
  'onsite-uatr': 'hconnectonsiteuatr', // https://hconnectonsiteuatr.z6.web.core.windows.net/
  'onsite-regression': 'hconnectonsiteregression', // https://hconnectonsiteregression.z6.web.core.windows.net/
  'onsite-prod': 'hconnectonsiteprod', // https://hconnectonsiteprod.z6.web.core.windows.net/

  // Insight
  'insight-qa': 'hconnectinsightqa', // https://hconnectinsightqa.z6.web.core.windows.net/
  'insight-uat': 'hconnectinsightuat', // https://hconnectinsightuat.z6.web.core.windows.net/
  'insight-regression': 'hconnectinsightregression', // https://hconnectinsightregression.z6.web.core.windows.net/
  'insight-prod': 'hconnectinsightprod', // https://hconnectinsightprod.z6.web.core.windows.net/

  // Predict
  'predict-qa': 'hconnectpredictqa', // https://hconnectpredictqa.z6.web.core.windows.net/
  'predict-uat': 'hconnectpredictuat', // https://hconnectpredictuat.z6.web.core.windows.net/
  'predict-regression': 'hconnectpredictregression', // https://hconnectpredictregression.z6.web.core.windows.net/
  'predict-prod': 'hconnectpredictprod', // https://hconnectpredictprod.z6.web.core.windows.net/

  // HProduce
  'hproduce-qa': 'hconnecthproduceqa', // https://hconnecthproduceqa.z6.web.core.windows.net/
  'hproduce-uat': 'hconnecthproduceuat', // https://hconnecthproduceuat.z6.web.core.windows.net/
  'hproduce-regression': 'hconnecthproduceregression', // https://hconnecthproduceregression.z6.web.core.windows.net/
  'hproduce-prod': 'hconnecthproduceprod', // https://hconnecthproduceprod.z6.web.core.windows.net/

  // Equippulse
  'equippulse-qa': 'hconnectmaintainqa', // https://hconnectmaintainqa.z6.web.core.windows.net/
  'equippulse-uat': 'hconnectmaintainuat', // https://hconnectmaintainuat.z6.web.core.windows.net/
  'equippulse-regression': 'hconnectmaintainregression', // https://hconnectmaintainregression.z6.web.core.windows.net/
  'equippulse-prod': 'hconnectmaintainprod', // https://hconnectmaintainprod.z6.web.core.windows.net/

  // POM
  'pom-qa': 'hconnectpomqa', // https://hconnectpomqa.z6.web.core.windows.net/
  'pom-uat': 'hconnectpomuat', // https://hconnectpomuat.z6.web.core.windows.net/
  'pom-regression': 'hconnectpomregression', // https://hconnectpomregression.z6.web.core.windows.net/
  'pom-prod': 'hconnectpomprod', // https://hconnectpomprod.z6.web.core.windows.net/

  // Truckmonitor
  'truckmonitor-qa': 'hconnecttmonqa', // https://hconnecttmonqa.z6.web.core.windows.net/
  'truckmonitor-uat': 'hconnecttmonuat', // https://hconnecttmonuat.z6.web.core.windows.net/
  'truckmonitor-uatr': 'hconnecttmonuatr', // https://hconnecttmonuatr.z6.web.core.windows.net/
  'truckmonitor-regression': 'hconnecttmonregression', // https://hconnecttmonuat.z6.web.core.windows.net/
  'truckmonitor-prod': 'hconnecttmonprod', // https://hconnecttmonprod.z6.web.core.windows.net/

  // Slot
  'slot-qa': 'hconnectslotqa', // https://hconnectslotqa.z6.web.core.windows.net/
  'slot-uat': 'hconnectslotuat', // https://hconnectslotuat.z6.web.core.windows.net/
  'slot-uatr': 'hconnectslotuatr', // https://hconnectslotuatr.z6.web.core.windows.net/
  'slot-regression': 'hconnectslotreg', // https://hconnectslotreg.z6.web.core.windows.net/
  'slot-prod': 'hconnectslotprod', // https://hconnectslotprod.z6.web.core.windows.net/

  // Metricsui
  'metricsui-qa': 'hconnectmetricsuiqa', // https://hconnectmetricsuiqa.z6.web.core.windows.net/
  'metricsui-prod': 'hconnectmetricsuiprod', // https://hconnectmetricsuiqa.z6.web.core.windows.net/
  'docs-dev': 'hconnectdocsdev',

  // Uikit/Storybook
  'uikit-qa': 'hconnectdocsdev',

  // Documentation
  'Arc42_Documentation-qa': 'hconnectdocsdev',
  'development_guidelines-qa': 'hconnectdocsdev',
  'DSE_Guidelines-qa': 'hconnectdocsdev'
}
export const getAzureStorageAccount = ({stage, product}: AzureTarget): string => {
  const storageBucket = azureStorageBuckets[`${product}-${stage}`]
  if (!storageBucket)
    throw new Error(
      `Error: Storage bucket does not exist for product ${product} and stage ${stage}!`
    )
  return storageBucket
}

export const STORAGE_BLOB_HOSTNAME = 'blob.core.windows.net'
export const STORAGE_PUBLIC_HOSTNAME = 'z6.web.core.windows.net'
export const STORAGE_BLOB_CONTAINER_NAME = '$web'

interface AzureTarget {
  stage: string
  product: string
}

/**
 * Returns the Azure storage account URL for account or stage and product.
 * e.g. https://hconnecttmonqa.blob.core.windows.net/$web
 *
 * @param target Target to retrieve the URL for
 */
export function getAzureStorageBlobUrl(azureStorageAccount: string): string {
  const host = `https://${azureStorageAccount}.${STORAGE_BLOB_HOSTNAME}`
  return `${host}/${STORAGE_BLOB_CONTAINER_NAME}`
}

/**
 * Returns a public URL for any target on Azure. A target is either
 * an account name or an internally known product and stage.
 *
 * @param target Target to retrieve the URL for
 */
export function getAzureStoragePublicUrl(azureStorageAccount: string): string {
  return `https://${azureStorageAccount}.${STORAGE_PUBLIC_HOSTNAME}`
}

export const getCoverageAzureBucketPublicUrl = (bucket: string): string => {
  return `${getAzureStoragePublicUrl('hconnectdocsdev')}/${bucket}/jest/`
}

export const getEslintAzureBucketPublicUrl = (bucket: string): string => {
  return `${getAzureStoragePublicUrl('hconnectdocsdev')}/${bucket}/eslint/`
}

export const getCypressCoverageAzureBucketPublicUrl = (bucket: string, product: string): string => {
  return `${getAzureStoragePublicUrl('hconnectdocsdev')}${bucket}/${product}/cypress/index.html`
}
export const getJestFolder = ({product}: {product: string}): string => {
  return path.join(product, 'jest')
}
export const getCypressFolder = ({product}: {product: string}): string => {
  return path.join(product, 'cypress')
}
export const getEslintFolder = ({product}: {product: string}): string => {
  return path.join(product, 'eslint')
}
