import {PRODUCTS} from '@hconnect/common/config/constants'
import {Grid, isoUtcDateFormatter, Page, SelectDropdown, Typography} from '@hconnect/uikit'
import {Box, Paper} from '@material-ui/core'
import * as d3 from 'd3'
import moment from 'moment'
import React from 'react'
import {useQuery} from 'react-query'

import {ChartBundleSize} from '../../../common/metrics/types'
import {CenteredSpinner} from '../components/CenteredSpinner'
import {ChartBundleSizeInBytes} from '../components/ChartBundleSizeInBytes'
import {ChartNumberOfFiles} from '../components/ChartNumberOfFiles'
import {ErrorGeneric} from '../components/ErrorGeneric'
import {TopNav} from '../components/TopNav'
import {useGlobal} from '../index.provider'

import {DefaultShell} from './DefaultShell'

interface DatetimeValue {
  datetime: string
  value: number
}

export const PageBundleCharts: React.FC = () => {
  const global = useGlobal()

  const searchParams = new URLSearchParams(global.location.search)
  const selectedProduct: string | undefined = searchParams.get('product') ?? ''
  if (!selectedProduct) {
    searchParams.set('product', PRODUCTS[0])
    global.history.push(`?${searchParams.toString()}`)
  }

  const {
    isLoading,
    error,
    data: bundleDTO
  } = useQuery<ChartBundleSize, Error>(`bundle-chart---${selectedProduct}`, async () => {
    const bundleDTO = await global.backend.getBundleCharts({
      product: selectedProduct,
      startDate: isoUtcDateFormatter(moment().subtract(4, 'weeks')),
      endDate: isoUtcDateFormatter(moment())
    })

    return bundleDTO
  })

  const getGzippedFileSizeTimeSeries = (
    fileExtension: string,
    bundleDTO?: ChartBundleSize
  ): DatetimeValue[] => {
    return (
      bundleDTO?.[fileExtension]?.map((d) => ({
        datetime: d.date,
        value: d.gzippedSizeInBytes
      })) ?? []
    )
  }

  const getNumberOfFilesTimeSeries = (
    fileExtension: string,
    bundleDTO?: ChartBundleSize
  ): DatetimeValue[] => {
    return (
      bundleDTO?.[fileExtension]?.map((d) => ({
        datetime: d.date,
        value: d.numberOfFiles
      })) ?? []
    )
  }

  const dropDownList = PRODUCTS.map((product) => ({key: product, title: product}))

  let content
  if (error) content = <ErrorGeneric error={error} />
  else if (isLoading || !bundleDTO) content = <CenteredSpinner />
  else {
    console.log(bundleDTO)

    const gridCells: React.ReactElement[] = []

    for (const fileExtension of Object.keys(bundleDTO).sort((a, b) => a.localeCompare(b))) {
      const gzippedFileSizeTimeSeries = getGzippedFileSizeTimeSeries(fileExtension, bundleDTO)
      const numberOfFiles = getNumberOfFilesTimeSeries(fileExtension, bundleDTO)
      const gridCell = (
        <Grid container key={fileExtension}>
          <Grid item xs={6}>
            <Typography variant="h4">{fileExtension} fileSize</Typography>
            <ChartBundleSizeInBytes
              data={gzippedFileSizeTimeSeries}
              minValue={0}
              maxValue={d3.max(gzippedFileSizeTimeSeries, (d) => d.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4">{fileExtension} numberOfFiles</Typography>
            <ChartNumberOfFiles
              data={numberOfFiles}
              minValue={0}
              maxValue={d3.max(numberOfFiles, (d) => d.value)}
            />
          </Grid>
        </Grid>
      )
      gridCells.push(gridCell)
    }

    content = (
      <>
        <Typography variant="h3">Gzipped bundle size of product {selectedProduct}</Typography>

        <SelectDropdown
          label="Product"
          options={dropDownList}
          renderItem={(item) => <>{item.title}</>}
          stringifyItem={(item) => `${item.title}`}
          onChange={(value: {key: string}) => {
            searchParams.set('product', value.key)
            global.history.push(`?${searchParams.toString()}`)
          }}
          selectedItem={dropDownList.find((element) => element.title === selectedProduct)}
        />

        <br />
        <br />

        {gridCells}
        {gridCells.length === 0 && <div>No data available.</div>}
      </>
    )
  }

  return (
    <DefaultShell NavItems={<TopNav />}>
      <Page title={'Bundle'}>
        <Paper>
          <Box padding={4}>{content}</Box>
        </Paper>
      </Page>
    </DefaultShell>
  )
}
