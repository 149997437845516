import {isoDateFormatter} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core'
import * as d3 from 'd3'
import moment from 'moment'
import React, {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'

export interface ChartAxisXProps {
  scale: d3.ScaleTime<number, number>
  // x and y are referring to the position of the x-axis
  x?: number
  y?: number
}

const useStyles = makeStyles((theme) => ({
  chartAxis: {
    ...theme.typography.subtitle1
  }
}))

const getXTicks = (scale) => {
  const [minValue, maxValue] = scale.domain()
  const ticks: Date[] = []

  let i = 0
  for (let date: Date = maxValue; date >= minValue; date = moment(date).add(-1, 'days').toDate()) {
    // only one tick per week
    if (i % 7 === 0) ticks.push(date)
    i++
  }
  return ticks.reverse()
}

export const ChartNumberOfFilesAxisX: React.FC<ChartAxisXProps> = ({scale, x = 0, y = 0}) => {
  const ref = useRef<SVGSVGElement>(null)
  const {
    i18n: {language}
  } = useTranslation()
  const {chartAxis} = useStyles()

  useEffect(() => {
    const myTickFormat = (d: string | Date) => isoDateFormatter(d)

    getXTicks(scale)

    if (ref.current)
      d3.select(ref.current)
        .attr('transform', `translate(${x},${y})`)
        .attr('class', chartAxis)
        .call(
          d3
            .axisBottom(scale)
            // .tickValues(['2020-10-12', '2021-05-02'])
            .tickValues(getXTicks(scale))
            .tickFormat(myTickFormat)
        )
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-35)')
  }, [ref, chartAxis, scale, x, y, language])

  return <g ref={ref} />
}
