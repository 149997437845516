import {makeStyles} from '@material-ui/core'
import * as d3 from 'd3'
import React, {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'

export interface ChartAxisXProps {
  scale: d3.ScaleTime<number, number>
  // x and y are referring to the position of the x-axis
  x?: number
  y?: number
}

const useStyles = makeStyles((theme) => ({
  chartAxis: {
    ...theme.typography.subtitle1
  }
}))

export const CoverageChartAxisX: React.FC<ChartAxisXProps> = ({scale, x = 0, y = 0}) => {
  const ref = useRef<SVGSVGElement>(null)
  const {
    i18n: {language}
  } = useTranslation()
  const {chartAxis} = useStyles()

  useEffect(() => {
    // const myTickFormat = d3.timeFormat('%b')
    const myTickFormat = (d) => d3.timeFormat('%b')(d)

    if (ref.current)
      d3.select(ref.current)
        .attr('transform', `translate(${x},${y})`)
        .attr('class', chartAxis)
        .call(d3.axisBottom(scale).ticks(d3.timeMonth.every(1)).tickFormat(myTickFormat))
  }, [ref, chartAxis, scale, x, y, language])

  return <g ref={ref} />
}
