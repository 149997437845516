import {HCTheme} from '@hconnect/uikit'
import {CircularProgress, CssBaseline} from '@material-ui/core'
import {ThemeProvider} from '@material-ui/styles'
import React, {Suspense, useMemo} from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {QueryClient, QueryClientProvider} from 'react-query'
import {useHistory, useLocation} from 'react-router-dom'

import {Backend} from './common/backend'
import {APIKEY} from './common/constants'
import {Global} from './common/types'
import {Head} from './components/helmet/Head'
import {initialState, State} from './index.state'

export const contextState = React.createContext<Global | undefined>(undefined)

export const useGlobal = (): Global => {
  const ctx = React.useContext(contextState)
  if (ctx === undefined) {
    throw new Error('useGlobal used outside of ContextProvider!')
  } else {
    return ctx
  }
}

export const Provider: React.FC = ({children}) => {
  const [state, setState] = React.useState<State>(initialState)
  const history = useHistory()
  const location = useLocation()
  const localStorage = window.localStorage
  const queryClient = useMemo(() => new QueryClient(), [])

  const backend = useMemo(
    () => new Backend(() => localStorage.getItem(APIKEY) ?? ''),
    [localStorage]
  )

  return (
    <Suspense fallback={<CircularProgress />}>
      <ThemeProvider theme={HCTheme}>
        <CssBaseline />
        <HelmetProvider>
          <Head />
          <contextState.Provider
            value={{
              state,
              setState,
              history,
              backend,
              location,
              localStorage
            }}
          >
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
          </contextState.Provider>
        </HelmetProvider>
      </ThemeProvider>
    </Suspense>
  )
}
