import {
  Box,
  Collapse,
  IconButton,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import {useTheme} from '@material-ui/styles'
import classNames from 'classnames'
import React, {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import Typography from '../Typography'

import {useStyle} from './DataTable.styles'
import {DataTablePagination, PaginationOptions} from './DataTablePagination'

export interface Column {
  key: string
  label?: string | JSX.Element
  labelKey?: string
  sortable?: boolean
  expandable?(item: any): boolean
  width?: string | number
  minWidth?: string | number
  flex?: React.CSSProperties['flexGrow']
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | string
  showUp?: boolean
  headProps?: Record<string, any>
  customTemplate?: (item: any) => React.ReactNode
}

interface DataTableProps {
  className?: string
  thClassName?: string
  tbClassName?: string
  variant?: string
  columns: Column[]
  data: Record<string, any>[]
  keyExtractor?(item: any): any
  onSort?(event: any, key: any): void
  expandable?: boolean
  isExpandable?(item: any, i: number): boolean
  onExpand?(event: React.MouseEvent, item: any, i: number): void
  isExpanded?(item: any, i: number): boolean
  expandRenderer?(arg: any): React.ReactNode
  rowClasses?: (item: any, i: number) => string
  sortedBy?: string
  sortDirection?: 'asc' | 'desc'
  emptyMessage?: React.ReactNode
  loading?: boolean
  rowProps?: Record<string, any>
  isClickable?: boolean | (() => void)
  showExpandIcon?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  onRowClick?(event: React.MouseEvent, item: any, i: number): void
  style?: React.CSSProperties
  isOverflowScroll?: boolean
  paginationOptions?: PaginationOptions
}

const DataTable: React.FC<DataTableProps> = ({
  className: classNameProp,
  thClassName,
  tbClassName,
  variant,
  columns,
  data,
  keyExtractor,
  onSort = () => {},
  expandable,
  isExpandable: isExpandableProp,
  onExpand,
  isExpanded,
  expandRenderer,
  rowClasses,
  sortedBy,
  sortDirection,
  emptyMessage,
  loading,
  rowProps,
  isClickable,
  showExpandIcon,
  onRowClick,
  isOverflowScroll,
  paginationOptions,
  ...restProps
}) => {
  const classes = useStyle()
  const theme = useTheme()
  const {t} = useTranslation()

  const handleExpand = (event: React.MouseEvent, item: Record<string, string>, i: number) => {
    event.stopPropagation()
    if (onExpand) {
      onExpand(event, item, i)
    }
  }

  const isExpandable = isExpandableProp !== undefined ? isExpandableProp : () => expandable

  return (
    <Table
      component="div"
      className={classNames(classes.root, variant ? classes[variant] : '', classNameProp, {
        [classes.clickable]: onExpand && isClickable,
        [classes.overflowScroll]: isOverflowScroll
      })}
      {...restProps}
    >
      <TableHead component="div" className={classNames(classes.tableHead, thClassName)}>
        <TableRow component="div" className={classes.loadingRow}>
          {Boolean(loading) && (
            <TableCell component="div" className={classes.loadingCell}>
              <LinearProgress data-test-id="DataTable-loadingIndicator" />
            </TableCell>
          )}
        </TableRow>
        <TableRow component="div" className={classNames(classes.tableRow, classes.row)}>
          {expandable && showExpandIcon ? (
            <TableCell
              component="div"
              key="expand"
              className={classNames(classes.expandCell, classes.tableCell)}
            />
          ) : null}
          {columns.map((column) => {
            const {key, label, labelKey, headProps, width, flex, showUp} = column
            const labelText = labelKey ? t(labelKey) : label
            const {style, ...otherHeadProps} = headProps || {}
            const align = column.align || 'left'
            // @ts-expect-error
            const isColumnVisible = (showUp && theme.breakpoints.up(showUp)) || !showUp

            return isColumnVisible ? (
              <TableCell
                key={key}
                data-test-id={`DataTable-columnHeader-${key}`}
                aria-label={`DataTable columnHeader ${key}`}
                component="div"
                className={classes.tableCell}
                align={align as any}
                sortDirection={sortedBy === key ? sortDirection : false}
                style={{
                  ...(width ? {width} : {flexGrow: flex || 1, flexBasis: 0}),
                  ...(style || {}),
                  ...(column.minWidth ? {minWidth: column.minWidth} : {})
                }}
                {...otherHeadProps}
              >
                {column.sortable ? (
                  <Tooltip
                    title={`${t('sortBy')} "${labelText}"`}
                    placement={align === 'right' ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      className={classes.sortableColumn}
                      active={sortedBy === key}
                      direction={sortDirection}
                      onClick={(event) => onSort(event, key)}
                      IconComponent={ArrowDownwardIcon}
                    >
                      {!label ? labelText : label}
                    </TableSortLabel>
                  </Tooltip>
                ) : !label ? (
                  labelText
                ) : (
                  label
                )}
              </TableCell>
            ) : null
          })}
        </TableRow>
      </TableHead>
      <TableBody className={classNames(classes.tableBody, tbClassName)} component="div">
        {data && data.length ? (
          // eslint-disable-next-line complexity
          data.map((item, i) => (
            <TableRow
              component="div"
              key={keyExtractor ? keyExtractor(item) : ''}
              className={classNames(classes.tableRow, rowClasses ? rowClasses(item, i) : '')}
              onClick={(event: React.MouseEvent) => {
                if (onExpand && isExpandable(item, i)) {
                  handleExpand(event, item, i)
                } else if (onRowClick) {
                  event.preventDefault()
                  onRowClick(event, item, i)
                }
              }}
              data-test-id={`DataTable-row-${keyExtractor ? keyExtractor(item) : ''}`}
              aria-label={`DataTable row ${i}`}
            >
              <div
                className={classes.row}
                {...rowProps}
                {...(!isExpandable(item, i) && {...{style: {cursor: 'default'}}})}
              >
                {expandable && showExpandIcon ? (
                  <TableCell
                    component="div"
                    key={`${keyExtractor ? keyExtractor(item) : ''}-expand`}
                    data-test-id="DataTable-column-expand"
                    className={classNames(classes.expandCell, classes.tableCell)}
                  >
                    {isExpandable(item, i) && (
                      <IconButton
                        onClick={(event) => handleExpand(event, item, i)}
                        className={classNames(
                          classes.iconExpand,
                          isExpanded && isExpanded(item, i) ? 'expanded' : 'collapsed'
                        )}
                      >
                        {isExpanded && isExpanded(item, i) ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                ) : null}
                {columns.map((column, index) => {
                  // @ts-expect-error
                  if ((column.showUp && theme.breakpoints.up(column.showUp)) || !column.showUp) {
                    const align = column.align || 'left'
                    return (
                      <TableCell
                        title={item[column.key]}
                        component="div"
                        data-test-id={`DataTable-column-${column.key}${index}`}
                        aria-label={`DataTable column ${column.key} ${i}-${index}`}
                        className={classes.tableCell}
                        key={`${keyExtractor ? keyExtractor(item) : ''}-${column.key}`}
                        align={align as any}
                        style={{
                          ...(column.width
                            ? {width: column.width}
                            : {flexGrow: column.flex || 1, flexBasis: 0}),
                          ...(column.minWidth ? {minWidth: column.minWidth} : {})
                        }}
                      >
                        {column.expandable && column.expandable(item) ? (
                          <Link
                            className={classes.expandLink}
                            onClick={(event: React.MouseEvent) => handleExpand(event, item, i)}
                          >
                            <Box display="flex" alignContent="center">
                              {column.customTemplate
                                ? column.customTemplate(item)
                                : item[column.key]}
                              {isExpanded && isExpanded(item, i) ? (
                                <KeyboardArrowDownIcon />
                              ) : (
                                <KeyboardArrowRightIcon />
                              )}
                            </Box>
                          </Link>
                        ) : (
                          <>
                            {column.customTemplate ? column.customTemplate(item) : item[column.key]}
                          </>
                        )}
                      </TableCell>
                    )
                  }
                  return null
                })}
              </div>
              {isExpandable(item, i) && (
                <Collapse
                  component="div"
                  key={`${keyExtractor ? keyExtractor(item) : ''}-expanded`}
                  data-test-id="DataTable-row-expand"
                  in={isExpanded && isExpanded(item, i)}
                  mountOnEnter
                  unmountOnExit
                >
                  {expandRenderer ? expandRenderer({item, index: i}) : <></>}
                </Collapse>
              )}
            </TableRow>
          ))
        ) : (
          <TableRow className={classes.tableRow} component="div">
            <TableCell className={classes.empty} component="div" data-test-id="DataTable-empty">
              {emptyMessage}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      {paginationOptions && <DataTablePagination paginationOptions={paginationOptions} />}
    </Table>
  )
}

DataTable.defaultProps = {
  variant: 'normal',
  data: [],
  keyExtractor: (item) => ('id' in item ? item.id : String(item)),
  emptyMessage: <Typography variant="caption">no data</Typography>,
  isExpanded: () => false,
  expandRenderer: () => null,
  isClickable: false,
  showExpandIcon: true
}

export const UncontrolledExpandableDataTable: React.FC<DataTableProps> = (props) => {
  const [expandedIndices, setExpandedIndices] = useState<number[]>([])
  const handleExpand = useCallback(
    (event, item, index) => {
      event.stopPropagation()
      if (expandedIndices.includes(index)) {
        setExpandedIndices(expandedIndices.filter((i) => i !== index))
      } else {
        setExpandedIndices([...expandedIndices, index])
      }
    },
    [expandedIndices, setExpandedIndices]
  )
  const isExpanded = useCallback(
    (item, index) => expandedIndices.includes(index),
    [expandedIndices]
  )
  return (
    <DataTable expandable isClickable onExpand={handleExpand} isExpanded={isExpanded} {...props} />
  )
}

export default DataTable
